<template>
  <v-dialog
    :value="true"
    :width="width"
    :fullscreen="isMobile"
    @click:outside="cancel"
    @keydown.esc="cancel"
  >
    <v-card :loading="isLoading" class="overflow-x-hidden">
      <transition
        :name="`${selectedEmployee ? 'slide-y-reverse' : 'slide-y'}-transition`"
        :duration="{ enter: 150, leave: 200 }"
        mode="out-in"
      >
        <EmployeeListItem
          v-if="hasSelectedEmployee"
          :employee="selectedEmployee"
          style="height: 64px"
          @click="onEmployeeSwap"
        >
          <template #action>
            <v-icon size="28" class="mr-1">$swapHorizontal</v-icon>
          </template>
        </EmployeeListItem>
        <v-text-field
          v-else
          v-model="employeeQuery"
          :placeholder="$t('auth.teamMode.employeePinDialog.placeholder')"
          append-icon="$vuetify.icons.search"
          height="64"
          hide-details
          clearable
          autofocus
          flat
          solo
        >
          <template #prepend-inner>
            <v-icon class="mr-2" @click="cancel">$vuetify.icons.back</v-icon>
          </template>
        </v-text-field>
      </transition>

      <v-divider />

      <v-fade-transition hide-on-leave>
        <v-card-text v-if="hasSelectedEmployee">
          <PinPad
            ref="pinPad"
            :label="pinMessage"
            :pin-length="4"
            :disabled="isLoading"
            :error-message.sync="pinErrorMessage"
            @finish="onPinFinish"
          />
        </v-card-text>
        <EmployeeList
          v-else
          :loading="loading"
          :employees="employees"
          :shifts="shifts"
          :query="employeeQuery"
          :height="compListHeight"
          @click:employee="onEmployeeClick"
        />
      </v-fade-transition>
    </v-card>
  </v-dialog>
</template>

<script>
import PinPad from "../pin-pad/PinPad.vue";
import EmployeeList from "./EmployeeList";
import EmployeeListItem from "./EmployeeListItem.vue";
import { useResponsiveness } from "@/core-ui";
import employeePinCache, {
  EmployeePinCacheResult,
} from "@/utils/employee-pin-cache";
import { hasRouteStore } from "@/mixins/routerParams";
import { format } from "date-fns";

import { SET_PIN } from "@/graphql/employee/mutations.gql";
import { VERIFY_PIN, STORE_EMPLOYEES } from "@/graphql/employee/queries.gql";
import { SHIFTS_TODAY } from "@/graphql/shift/queries.gql";

export default {
  name: "EmployeePinDialog",
  components: { PinPad, EmployeeList, EmployeeListItem },
  mixins: [useResponsiveness()],
  inject: ["currentStore"],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 400,
    },
  },
  data: () => ({
    pinLoading: false,
    pinErrorMessage: "",
    newPinValue: null,
    employeeQuery: null,
    selectedEmployee: null,
  }),
  computed: {
    hasSelectedEmployee() {
      return !!this.selectedEmployee && this.selectedEmployee != {};
    },
    compListHeight() {
      return this.isDesktop ? 500 : "calc(100vh - 65px)";
    },
    pinMessage() {
      if (this.shouldSetPin) {
        return this.$t("auth.teamMode.employeePinDialog.setPIN");
      } else if (this.shouldVerifyPin) {
        return this.$t("auth.teamMode.employeePinDialog.verifyPIN");
      }

      return this.$t("auth.teamMode.employeePinDialog.enterPIN");
    },
    shouldSetPin() {
      return !this.selectedEmployee?.hasPin && !this.newPinValue;
    },
    shouldVerifyPin() {
      return !this.selectedEmployee?.hasPin && !!this.newPinValue;
    },
    isLoading() {
      return this.loading || this.pinLoading || this.$apollo.loading;
    },
    storeId() {
      return this.currentStore()?.id;
    },
  },
  mounted() {
    if (
      this.$store.state.teamMode.lastEmployee &&
      this.$store.state.teamMode.lastEmployee !== {}
    ) {
      this.selectedEmployee = this.$store.state.teamMode.lastEmployee;
      this.$store.dispatch("teamMode/clearResetTimeout");
    }
  },
  apollo: {
    shifts: {
      query: SHIFTS_TODAY,
      variables() {
        return {
          storeId: this.storeId,
          businessDate: format(new Date(), "yyyy-MM-dd"),
        };
      },
      skip() {
        return !this.storeId;
      },
    },
    employees: {
      query: STORE_EMPLOYEES,
      variables() {
        return {
          storeId: this.storeId,
        };
      },
      skip() {
        return !this.storeId;
      },
    },
  },
  methods: {
    onEmployeeClick(employee) {
      this.employeeQuery = null;
      this.selectedEmployee = employee;
    },
    onEmployeeSwap() {
      this.reset();
    },
    onPinFinish(value) {
      if (this.shouldSetPin) {
        this.newPinValue = value;
        this.$refs.pinPad.clear();
      } else if (this.shouldVerifyPin) {
        if (value === this.newPinValue) {
          this.pinErrorMessage = "";
          this.setPin(value);
        } else {
          this.pinErrorMessage = this.$t(
            "auth.teamMode.employeePinDialog.PINDoesNotMatch"
          );
        }
      } else {
        this.verifyPin(value);
      }
    },
    async setPin(value) {
      this.pinLoading = true;

      try {
        await this.$apollo.mutate({
          mutation: SET_PIN,
          context: { skipEmployeeValidation: true },
          variables: {
            employeeId: this.selectedEmployee.id,
            pin: value,
          },
        });

        await this.verifyPin(value);
        this.newPinValue = null;
      } catch {
        this.pinErrorMessage = this.$t(
          "auth.teamMode.employeePinDialog.PINSetupFailed"
        );
      } finally {
        this.pinLoading = false;
      }
    },
    async verifyPin(value) {
      this.pinLoading = true;

      try {
        const employeeId = this.selectedEmployee.id;
        let pinResult = employeePinCache.check(employeeId, value);

        if (pinResult === EmployeePinCacheResult.NOT_FOUND) {
          pinResult = await this.$apollo
            .query({
              query: VERIFY_PIN,
              fetchPolicy: "network-only",
              variables: { employeeId, pin: value },
              context: { skipEmployeeValidation: true },
            })
            .then((response) => response.data.verifyPin.result);
        }

        if (!pinResult) {
          throw new Error(
            this.$t("auth.teamMode.employeePinDialog.invalidPINError")
          );
        }

        employeePinCache.set(employeeId, value);
        this.ok();
      } catch (e) {
        this.pinErrorMessage = e.message;
      } finally {
        this.$refs.pinPad.clear();
        this.pinLoading = false;
      }
    },
    ok() {
      this.$emit("ok", this.selectedEmployee);
      this.reset();
    },
    cancel() {
      this.$emit("cancel");
      this.reset();
    },
    reset() {
      this.newPinValue = null;
      this.pinErrorMessage = "";
      this.selectedEmployee = null;
      this.$refs.pinPad?.clear();
    },
  },
};
</script>
