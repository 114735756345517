<template>
  <v-list-item
    :class="['employee-list-item', statusClasses]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <EmployeeAvatar :employee="employee" :hide-status="hideStatus" list-item />
    <v-list-item-content>
      <v-list-item-title>
        {{ employee.fullName }}
      </v-list-item-title>
      <v-slide-y-transition mode="out-in">
        <v-list-item-subtitle v-if="status" :key="status" class="text-caption">
          {{ status }}
        </v-list-item-subtitle>
      </v-slide-y-transition>
    </v-list-item-content>
    <v-list-item-action>
      <slot name="action">
        <v-slide-x-reverse-transition>
          <v-list-item-action-text v-if="employee.drsEmployeeCode">
            {{ employee.drsEmployeeCode }}
          </v-list-item-action-text>
        </v-slide-x-reverse-transition>
      </slot>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import EmployeeAvatar from "./EmployeeAvatar.vue";
import { makeStatusProps, useStatus } from "@/composables/employee/status";

export default {
  name: "EmployeeListItem",
  components: { EmployeeAvatar },
  props: {
    ...makeStatusProps(),
  },
  setup(props) {
    const { status, statusClasses } = useStatus(props, "employee-list-item");
    return { status, statusClasses };
  },
};
</script>

<style lang="scss">
.employee-list-item.employee-list-item--clocked-out {
  opacity: 0.8;
}
</style>
