import { defineComponent } from "vue";

const BaseListLazy = () => import("./_BaseList.vue");
const StatusListLazy = () => import("./_StatusList.vue");

export default defineComponent({
  name: "EmployeeList",
  inject: {
    currentStore: {
      default() {
        return () => ({
          usesExternalScheduler: false,
        });
      },
    },
  },
  props: {
    hideStatus: {
      type: Boolean,
      default: null,
    },
  },
  functional: true,
  render(createElement, context) {
    const { usesExternalScheduler } =
      context.injections.currentStore?.call() ?? {};

    return createElement(
      context.props.hideStatus ?? !usesExternalScheduler
        ? BaseListLazy
        : StatusListLazy,
      context.data,
      context.children
    );
  },
});
