import SHA256 from "crypto-js/sha256";

import { format, isSameDay, parseISO } from "date-fns";

/**
 * @typedef {Object} EmployeePinResult
 * @property {Number} accessTime - employee's access time
 * @property {String} checksum - encoded employee PIN
 */

/**
 * Enum for employee pin cache check
 * @enum {Number}
 * @readonly
 */
export const EmployeePinCacheResult = Object.freeze({
  VALID: 1,
  INVALID: 0,
  NOT_FOUND: -1,
});

class EmployeePinCache {
  constructor() {
    /** @type {Map<String,EmployeePinResult>} */
    this.cache = new Map();
  }

  /**
   * If the employee is in the cache and the date is today, then return 1, otherwise return 0.
   * If the employee is not in the cache or the date is not today, return -1
   * @param {String} employeeId - The employee's ID
   * @param {String} pin - The employee PIN to check
   * @returns the flag for PIN check.
   */
  check(employeeId, pin) {
    const item = this.cache.get(employeeId);

    if (
      item &&
      isSameDay(
        typeof item.accessTime === "string"
          ? parseISO(item.accessTime)
          : item.accessTime,
        new Date()
      )
    ) {
      return item.checksum === this.encode(pin)
        ? EmployeePinCacheResult.VALID
        : EmployeePinCacheResult.INVALID;
    }

    return EmployeePinCacheResult.NOT_FOUND;
  }

  /**
   * It sets a cache entry for the given employee, with a checksum of the given PIN
   * @param {String} employeeId - The employee's ID
   * @param {String} pin - The PIN that the employee entered.
   */
  set(employeeId, pin) {
    this.cache.set(employeeId, {
      accessTime: new Date().getTime(),
      checksum: this.encode(pin),
    });
  }

  /**
   * It takes a pin and returns a hash of the current date and the pin
   * @param {String} pin - The pin that the user enters
   * @returns A hash of the date and the pin.
   */
  encode(pin) {
    return SHA256(`${format(new Date(), "yyyy-MM-dd")}.${pin}`).toString();
  }
}

export default new EmployeePinCache();
