<template>
  <v-dialog v-model="modelValue" :width="width" persistent>
    <v-card>
      <v-card-title>
        {{
          $t("auth.teamMode.employeeWelcomeDialog.title", {
            name: employee.firstName,
          })
        }}
      </v-card-title>
      <v-card-text class="pt-4">
        <p v-if="employeeShift">
          {{
            $t("auth.teamMode.employeeWelcomeDialog.shiftsToday", {
              shiftStart: shiftStartAt,
              shiftEnd: shiftEndAt,
            })
          }}
        </p>
        <p>
          {{
            $tc(
              "auth.teamMode.employeeWelcomeDialog.tasksAssigned",
              assignedTasks
            )
          }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <VxBtn secondary text @click="logout">
          {{ $t("auth.teamMode.employeeWelcomeDialog.logoutText") }}
        </VxBtn>
        <VxBtn text @click="keepWorking">
          {{ $t("auth.teamMode.employeeWelcomeDialog.keepWorkingText") }}
        </VxBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VxBtn, useModelValue } from "@/core-ui";
import { materialTime } from "@/utils/datetime";

export default {
  name: "EmployeeWelcomeDialog",
  components: { VxBtn },
  mixins: [useModelValue()],
  props: {
    shifts: {
      type: Array,
      default: () => [],
    },
    todos: {
      type: Array,
      default: () => [],
    },
    taskLists: {
      type: Array,
      default: () => [],
    },
    width: {
      type: [String, Number],
      default: 400,
    },
  },
  computed: {
    employee() {
      return this.$store.state.teamMode.employee;
    },
    employeeShift() {
      return this.shifts.find(
        (shift) => shift.employee?.id === this.employee.id
      );
    },
    shiftStartAt() {
      return materialTime(this.employeeShift.scheduledStartAt);
    },
    shiftEndAt() {
      if (this.employeeShift.scheduledEndAt) {
        return materialTime(this.employeeShift.scheduledEndAt);
      }

      return null;
    },
    assignedTasks() {
      const assignedTodos = this.todos.filter(
        (todo) => todo.employee?.id === this.employee.id
      ).length;

      const assignedTasks = this.taskLists.reduce((total, taskList) => {
        const assignedToEmployee = taskList.tasks.filter(
          (task) =>
            !task.completed?.completedAt &&
            task.completed?.employee?.id === this.employee.id
        );
        return total + assignedToEmployee.length;
      }, 0);

      return assignedTodos + assignedTasks;
    },
  },
  methods: {
    logout() {
      this.dialog = false;
      this.$emit("close");
      this.$store.dispatch("auth/lockTeamMode");
    },
    keepWorking() {
      this.dialog = false;
      this.$emit("close");
    },
  },
};
</script>
